import { getDoctors } from '@/api/doctor'
import { useTableOptions } from '@/composables'
import doctorStore from '@/modules/doctor/store'
import store from '@/store'
import { reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []
const STORE_NAME = 'doctor_list'

export default () => {
  const loading = ref(false)

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Name', value: 'name' },
    { text: 'Address', value: 'address' },
    { text: 'Phone', value: 'phone' },
    { text: 'Price', value: 'price' },
    { text: 'Active', value: 'active' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      itemsPerPage: 15,
    },
  )

  // Filter
  const filters = reactive({
    group_id: null,
    name: null,
  })

  const loadDoctors = async () => {
    loading.value = true

    try {
      const {
        status,
        data: { records, pagination },
      } = await getDoctors(useTableOptions(options.value, parseFilters()))

      if (status === 'success') {
        tableItems.value = records
        tableTotal.value = pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce((a, key) => ({ ...a, [key]: filters[key] }), {})

  watch([options], () => {
    loadDoctors()
  })

  watch(
    () => filters.group_id,
    () => {
      loadDoctors()
    },
  )

  watch(
    () => filters.name,
    debounce(() => {
      loadDoctors()
    }, 800),
  )

  const registerStore = () => {
    if (!store.hasModule(STORE_NAME)) {
      store.registerModule(STORE_NAME, doctorStore)
    }
  }

  const unregisterStore = () => {
    if (store.hasModule(STORE_NAME)) {
      store.unregisterModule(STORE_NAME)
    }
  }

  return {
    loading,

    tableColumns,
    tableItems,
    tableTotal,

    options,
    filters,

    loadDoctors,

    registerStore,
    unregisterStore,
  }
}
